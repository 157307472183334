import './App.css';
import fiull from '../src/imagens/fiull.png'

function App() {
  return (
    <div className="App">
      <div className='Logo'>
        <img src={fiull} alt="Logo"/>
      </div>
    </div>
  );
}

export default App;
